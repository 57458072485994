import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CheckPicker, Stack } from "rsuite";
import Navbar from "../components/Navbar";
import ActiveButton from "../Elements/ActiveButton";
import BackButton from "../Elements/BackButton";
import Container from "../Elements/Container";
import { activeBook, activeCon } from "../redux/ActivationSlice";
import { selectCities, selectRole } from "../redux/LoginSlice";
import { restaurentsData } from "../redux/RestaurantsSlice";
import Bookings from "./Bookings";
import Content from "./Content";

function Restraurents() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [ids, setIds] = useState([]);
  const [restro, setRestro] = useState([]);
  // const [initialized, setInitialized] = useState(false);  
  const role = useSelector(selectRole);
  const cities = useSelector(selectCities);

  const token = useSelector((state) => state?.loginUser?.token);
  // const user = useSelector(selectIs);

  let city;
  if (id === "1") city = "Milano";
  else if (id === "3") city = "Bali";
  else if (id === "2") city = "Dubai";

  useEffect(() => {
    if (token) {
      dispatch(restaurentsData({ id, token }));
    }
  }, [id, token, dispatch]);

  const handleValueChange = (value) => setRestro(value);
  const handleIdChange = () => setIds(restro);

  const handleBookingPage = () => {
    dispatch(activeBook(true));
  };
  const handleContentPage = () => {
    dispatch(activeCon(true));
  };


  // useEffect(()=>{
  //   if(user) OneSignal.login(user.id.toString());
  // },[user])


  const isopen = useSelector((state) => state?.activationButton?.ModalAction);
  const bookingActive = useSelector(
    (state) => state.activationButton.activateBooking
  );
  const contentActive = useSelector(
    (state) => state.activationButton.activateContent
  );

  const data = useSelector((state) => state?.restaurant?.restaurents);
  const restrau = data?.map((item) => ({ label: item.Name, value: item.id }));

  return (
    <div className={`w-full mb-4 ${isopen ? "blur-sm" : ""} `}>
      <Navbar />
      <Container>
        <div className="m-6 flex justify-between">
          {cities?.length === 0 || cities?.length === 2 ? (
            <BackButton />
          ) : (
            <div></div>
          )}
          <h1 className="font-semibold text-4xl text-center">{city}</h1>
          <div></div>
        </div>
        <div className="mt-4 flex flex-col justify-center gap-2 md:gap-0 flex-wrap md:flex-row md:justify-between items-center px-10 pb-8">
          <div className="flex gap-2">
            <ActiveButton
              onClick={handleBookingPage}
              contentActive={bookingActive}
              text="Bookings"
            />
            <ActiveButton
              onClick={handleContentPage}
              contentActive={contentActive}
              text="Content"
            />
          </div>
          <div className="flex gap-2 self-end ">
            <Stack spacing={10} direction="row" alignItems="flex-start">
              <CheckPicker
                style={{ boxShadow: "none" }}
                value={restro}
                data={restrau}
                className="w-[220px] md:w-[500px]"
                onChange={handleValueChange}
                onClean={() => setIds([])}
              />
            </Stack>
            <Button
              onClick={handleIdChange}
              className="bg-[#FF004F] text-white"
            >
              Apply
            </Button>
          </div>
          {role === "admin" ? (
            <Button onClick={() => navigate("/influencers")} appearance="ghost">
              Users
            </Button>
          ) : (
            <div></div>
          )}
        </div>
        {bookingActive ? (
          <Bookings ids={ids} restraurantId={id} />
        ) : (
          <Content ids={ids} restraurantId={id} />
        )}
      </Container>
    </div>
  );
}

export default Restraurents;
