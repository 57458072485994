import React, { useEffect, useState } from "react";
import OneSignal from "react-onesignal";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectIs, selectIsLoggedIn } from "./redux/LoginSlice";

const ProtectedRoute = () => {
  const token = useSelector(selectIsLoggedIn);
  const [permissionAsk, setPermission] = useState(false)
  const user = useSelector(selectIs);

  const requestPermission = async () => {

    if (typeof window !== 'undefined'  && token && user) {
      try {
        console.log(process.env.REACT_APP_ONE_SIGNAL_ID,'id')
        await OneSignal.init({
          appId: process.env.REACT_APP_ONE_SIGNAL_ID,
          notifyButton: {
            enable: true,
          },
          allowLocalhostAsSecureOrigin: true
        })
        setPermission(true)
        
        // OneSignal.Debug.setLogLevel('trace');

      } catch (err) {
        if (typeof err === 'string' && err.includes('already initialized')) {
          setPermission(true)
        }
        console.log(err)
      }
    }
  }


  useEffect(() => {
    requestPermission()
    const listner = () => { console.log('background') }
    OneSignal.Notifications.addEventListener('foregroundWillDisplay', listner)
    return () => {
      OneSignal.Notifications.removeEventListener('foregroundWillDisplay', listner)
    }
  }, []);
  useEffect(() => {
    if (user?.id && permissionAsk) {
      console.log('You have permission to')

      // if (!OneSignal.Notifications.permission) {

      OneSignal.Slidedown.promptPush().then(async () => {

        await OneSignal.Notifications.requestPermission()
        await OneSignal.login(user.id.toString());
      })
      // }
    } 
  }, [user?.id, permissionAsk])
  console.log(user,'user',permissionAsk)
  // if (!token) {
  //     return <Navigate to="/login" replace />;
  // }
  // return <Outlet />;
  return token ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
